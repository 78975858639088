import React from "react"

import SEO from '../components/seo'

import DarkLayout from "../components/dark_layout"

const FilmmakerPage = () => (
  <DarkLayout>
    <SEO title='Cinema Garden' />
  </DarkLayout>
)

export default FilmmakerPage
